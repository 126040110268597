/* global */

$(document).foundation();
/* eslint-disable */
$.isInViewport = (el) => {
  const elementTop = $(el).offset().top + 500;
  const elementBottom = elementTop + $(el).outerHeight();
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(() => {
  $.slidebars();

  $('.close-top-banner').click(function () {
    $(this).parent().slideUp(), $(this).parent().parent().find('.header').removeClass('has-top-banner');
  });



  /* eslint-disable */
  $('#menu-slidebars-menu > li.menu-item-has-children > a').click((e) => {
    e.preventDefault();
    const mobileMenuItem = e.target;
    if ($(mobileMenuItem).hasClass('active')) {
      $(mobileMenuItem).removeClass('active').removeClass('open');
      $(mobileMenuItem).next().slideUp();
    } else {
      $(mobileMenuItem).addClass('active').addClass('open');
      $(mobileMenuItem).next().slideDown();
    }
  });

  $('body').on('click', '.faq-toggle-btn', function () {
    $(this).toggleClass('opened');
    $(this).parent().siblings('.faq-answers').slideToggle(500);
  });

  $('body').on('click', '.faq-questions > p', function () {
    $(this).siblings('.faq-toggle-btn').toggleClass('opened');
    $(this).parent().siblings('.faq-answers').slideToggle(500);
  });

  $('.blog-slider, .discover-more').owlCarousel({
    loop: true,
    margin: 1,
    dots: true,
    nav: true,
    navText: [
      "<span><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-blog-prev.png'></span>",
      "<span><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-blog-next.png'></span>"
    ],
    items: 3,
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true
      },

      600: {
        items: 2,
        nav: false,
        dots: true
      },

      1050: {
        nav: true
      }
    }
  });

  $('.row-slider').owlCarousel({
    center: true,
    items: 2,
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      "<span><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-slide-prev.png'></span>",
      "<span><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-slide-next.png'></span>"
    ],
    responsive: {
      500: {
        items: 1.5
      },
      1020: {
        items: 2
      }
    }
  });

  $('.row-slider-mobile').owlCarousel({
    items: 1,
    loop: true,
    nav: false,
    dots: true
  });

  // MENU SLIDER

  $('#menu-main-menu > li:last-child').addClass('action action--open').attr('aria-label', 'Open Menu');

  var menuEl = document.getElementById('ml-menu'),
    mlmenu = new MLMenu(menuEl, {
      breadcrumbsCtrl: false, // show breadcrumbs
      // initialBreadcrumb : 'all', // initial breadcrumb text
      backCtrl: true // show back button
    });

  // mobile menu toggle
  var openMenuCtrl = document.querySelector('.action--open'),
    closeMenuCtrl = document.querySelector('.action--close'),
    sliderOverlay = document.querySelector('.slider-menu-overlay');

  closeMenuCtrl.addEventListener('click', closeMenu);
  openMenuCtrl.addEventListener('click', openMenu);

  $('.action--open').click(function () {
    openMenu();
  });

  function openMenu() {
    classie.add(menuEl, 'menu--open');
    closeMenuCtrl.focus();
    $('.slider-menu-overlay-wrap').css('height', 'calc(100%)');
    $('.slider-menu-overlay').fadeIn();
    $('.slider-menu-btns').css({
      top: $('.menu__level[data-menu="main"]').height() + 'px'
    });
    $('#menu-slidebars-menu > li.current-menu-parent > a').addClass('active').addClass('open');

    $('#menu-slidebars-menu > li.current-menu-parent').find('.sub-menu').css('display', 'block');
  }

  function closeMenu() {
    classie.remove(menuEl, 'menu--open');
    openMenuCtrl.focus();

    $('.slider-menu-overlay-wrap').css('height', '0');
    $('.slider-menu-overlay').fadeOut();
  }

  sliderOverlay.onclick = function () {
    closeMenuCtrl.click();
  };

  $('.owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      "<span class='anim-zoom'><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-prev.png'></span>",
      "<span class='anim-zoom'><img src='/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-next.png'></span>"
    ],
    dots: false,
    items: 1,
    autoHeight: true,
    responsive: {
      0: {
        nav: false,
        dots: true
      },
      600: {
        nav: true,
        dots: false
      }
    }
  });

  $('#collapsing-tabs li').click(function () {
    $(this).parent().attr('style', '--i:' + $(this).data('i'));
  });

  $(document).on('click', 'a[href^="#"]', (event) => {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $(`a[name="${$(event.currentTarget).attr('href')}"]`).offset().top - 200
      },
      500
    );
  });

  $('.btn-lines-wrapper').click(function (event) {
    if ($(this).find('.btn-lines').attr('href').indexOf('#') >= 0 && !$(this).hasClass('job-btn')) {
      event.preventDefault();
      var target = $(this).find('.btn-lines').attr('href').replace('#', '');

      $('html, body').animate({
          scrollTop: $(`a[name="${target}"]`).offset().top - 200
        },
        700
      );
    }
  });

  $;

  // subpage scroll to
  $('.content-nav-subpage .subnav-menu a').click(function (e) {
    var id = $(this).attr('href');
    if (id.includes('#')) {
      e.preventDefault();
      $([document.documentElement, document.body]).animate({
          scrollTop: $(id).offset().top - 120
        },
        1300
      );
    }
  });

  /* eslint-disable */
  StickyHeader();
  $(window).scroll(function (event) {
    StickyHeader();


  });

  // Job application form options
  /* global availablePositions */
  if (typeof availablePositions !== 'undefined') {
    $.each(availablePositions, (key, value) => {
      console.log(value);
      if (value.active) {
        $('select[name=selected-position]').append(
          `<option value="${value.title}" selected="selected" data-id="${value.id}">${value.title}</option>`
        );
      } else {
        $('select[name=selected-position]').append(
          `<option value="${value.title}" data-id="${value.id}">${value.title}</option>`
        );
      }
    });

    /* eslint-disable */
    const els = document.querySelectorAll('input[type=file]');
    for (let i = 0; i < els.length; i++) {
      els[i].addEventListener('change', function () {
        const labelFor = $(this).attr('id');
        $('label[for=' + labelFor + ']').text(this.files[0].name);
      });
    }

    // scroll to form
    if (window.location.hash == '#apply-online') {
      $([document.documentElement, document.body]).animate({
          scrollTop: $('#job-application-form').offset().top - 470
        },
        500
      );
    }
  }
});

/* global AOS */
$(window).on('load', () => {
  AOS.init();
});

const StickyHeader = () => {
  var scroll = $(window).scrollTop();
  if ($(".header").hasClass("has-top-banner")) {

    if (scroll > 198) {
      $('.header').addClass('fixed-header-nav');
    } else {
      $('.header').removeClass('fixed-header-nav');

    }
  } else {
    if (scroll > 5) {
      $('.header').addClass('fixed-header-nav');

    } else {
      $('.header').removeClass('fixed-header-nav');

    }
  }

};

// hover effect for more to celebrate
$(document).ready(function () {



  $('.pod').mouseenter(function () {
    $(this).find('.hidden-content').stop().slideDown(500);
  });
  $('.pod').mouseleave(function () {
    $(this).find('.hidden-content').stop().slideUp(500);
  });

  $('.area-1')
    .mouseenter(function () {
      $('.hover-map-1').css({
        opacity: 1
      });
      $('.marker-0 img, .marker-1 img').css({
        transform: 'scale(1)'
      });
      $('.location-0, .location-1').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-1').css({
        opacity: 0
      });
      $('.marker-0 img, .marker-1 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-0, .location-1').removeClass('active');
    });

  $('.area-2')
    .mouseenter(function () {
      $('.hover-map-2').css({
        opacity: 1
      });
      $('.marker-2 img').css({
        transform: 'scale(1)'
      });
      $('.location-2').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-2').css({
        opacity: 0
      });
      $('.marker-2 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-2').removeClass('active');
    });

  $('.area-3')
    .mouseenter(function () {
      $('.hover-map-3').css({
        opacity: 1
      });
      $('.marker-4 img').css({
        transform: 'scale(1)'
      });
      $('.location-3').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-3').css({
        opacity: 0
      });
      $('.marker-4 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-3').removeClass('active');
    });

  $('.area-4')
    .mouseenter(function () {
      $('.hover-map-4').css({
        opacity: 1
      });
      $('.marker-5 img').css({
        transform: 'scale(1)'
      });
      $('.location-6').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-4').css({
        opacity: 0
      });
      $('.marker-5 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-6').removeClass('active');
    });

  $('.area-5')
    .mouseenter(function () {
      $('.hover-map-6').css({
        opacity: 1
      });
      $('.marker-3 img').css({
        transform: 'scale(1)'
      });
      $('.location-4').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-6').css({
        opacity: 0
      });
      $('.marker-3 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-4').removeClass('active');
    });

  $('.area-6')
    .mouseenter(function () {
      $('.hover-map-5').css({
        opacity: 1
      });
      $('.marker-6 img').css({
        transform: 'scale(1)'
      });
      $('.location-5').addClass('active');
    })
    .mouseleave(function () {
      $('.hover-map-5').css({
        opacity: 0
      });
      $('.marker-6 img').css({
        transform: 'scale(0.9)'
      });
      $('.location-5').removeClass('active');
    });

  $('.location-0').mouseenter(function () {
    $('.hover-map-1').css({
      opacity: 1
    });
    $('.marker-1 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-0').mouseleave(function () {
    $('.hover-map-1').css({
      opacity: 0
    });
    $('.marker-1 img').css({
      transform: 'scale(0.9)'
    });
  });

  $('.location-1').mouseenter(function () {
    $('.hover-map-1').css({
      opacity: 1
    });
    $('.marker-0 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-1').mouseleave(function () {
    $('.hover-map-1').css({
      opacity: 0
    });
    $('.marker-0 img').css({
      transform: 'scale(0.9)'
    });
  });
  $('.location-2').mouseenter(function () {
    $('.hover-map-2').css({
      opacity: 1
    });
    $('.marker-2 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-2').mouseleave(function () {
    $('.hover-map-2').css({
      opacity: 0
    });
    $('.marker-2 img').css({
      transform: 'scale(0.9)'
    });
  });

  $('.location-3').mouseenter(function () {
    $('.hover-map-3').css({
      opacity: 1
    });
    $('.marker-4 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-3').mouseleave(function () {
    $('.hover-map-3').css({
      opacity: 0
    });
    $('.marker-4 img').css({
      transform: 'scale(0.9)'
    });
  });

  $('.location-4').mouseenter(function () {
    $('.hover-map-6').css({
      opacity: 1
    });
    $('.marker-3 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-4').mouseleave(function () {
    $('.hover-map-6').css({
      opacity: 0
    });
    $('.marker-3 img').css({
      transform: 'scale(0.9)'
    });
  });

  $('.location-5').mouseenter(function () {
    $('.hover-map-5').css({
      opacity: 1
    });
    $('.marker-6 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-5').mouseleave(function () {
    $('.hover-map-5').css({
      opacity: 0
    });
    $('.marker-6 img').css({
      transform: 'scale(0.9)'
    });
  });

  $('.location-6').mouseenter(function () {
    $('.hover-map-4').css({
      opacity: 1
    });
    $('.marker-5 img').css({
      transform: 'scale(1)'
    });
  });

  $('.location-6').mouseleave(function () {
    $('.hover-map-4').css({
      opacity: 0
    });
    $('.marker-5 img').css({
      transform: 'scale(0.9)'
    });
  });

  // toggle btn

  $('.toggle-btn').click(function () {
    $(this).toggleClass('open');
    $('.contact-form ').slideToggle();
  });

  $('.map-form-submit').click(function (e) {
    e.preventDefault();
    var startAddress = $('.map-form-start-address').val();
    calculateAndDisplayRoute(directionsService, directionsRenderer, startAddress);
  });

  $('.job-application .file button').on('click', function (e) {
    e.preventDefault();
    $(this).siblings('span').find('input').click();
    $('.file  input[type="file"]').change(function (e) {
      var fileName = e.target.files[0].name;
      $(this).parent().siblings('.file-label').text(fileName).css({
        color: '#ef4023',
        'font-style': 'italic'
      });
    });
  });
  $(window).resize(function () {
    if ($('.entry.dynamic-content.instagram').length > 0) {
      var instaImage = $('.dynamic-content .instagram-block .row-dynamic-content .cell p');
      instaImage.css('max-height', instaImage.width() + 'px');
    }
  });

  $('.show-more-tags').click(function (e) {
    e.preventDefault();

    if ($(this).parent().children(':hidden').length < 20) {
      $(this).text('Hide Tags');
    }

    if ($(this).parent().children(':visible').length == $(this).parent().find('a').length) {
      $(this).parent().find('.hidden').hide();
      $(this).text('Show More');
    } else {
      $(this).parent().find('.hidden:not(:visible):lt(20)').show();
    }
  });
});
